import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IDynamicListItem,
  IDynamicListStatusItem,
  getDataPointValue,
  getDataPointsStatus,
  getQuestionValue,
  getQuestionsStatus,
  selectDataPointValueFulfilledForIds,
  selectDataPoints,
  selectDataPointsStatus,
  selectQuestionValueFulfilledForIds,
  selectQuestions,
  selectQuestionsStatus,
} from "store/chat-assistant";

const REFETCH_INTERVAL_MS = 5000;

type TypeOptions = "dataPoints" | "questions";

interface IProps {
  botId: string;
  type: TypeOptions;
  refetchInterval?: number;
}

const selectData: Record<TypeOptions, any> = {
  dataPoints: selectDataPoints,
  questions: selectQuestions,
};

const selectStatus: Record<TypeOptions, any> = {
  dataPoints: selectDataPointsStatus,
  questions: selectQuestionsStatus,
};

const selectFulfilledForIds: Record<TypeOptions, any> = {
  dataPoints: selectDataPointValueFulfilledForIds,
  questions: selectQuestionValueFulfilledForIds,
};

const fetchData: Record<TypeOptions, any> = {
  dataPoints: getDataPointValue,
  questions: getQuestionValue,
};

const fetchStatus: Record<TypeOptions, any> = {
  dataPoints: getDataPointsStatus,
  questions: "",
};

export const useDynamicChecklist = ({ botId, type }: IProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const items = (useSelector(selectData[type]) || []) as IDynamicListItem[];
  const itemsStatus = (useSelector(selectStatus[type]) ||
    []) as IDynamicListStatusItem[];
  const itemValueFulfilledForIds = (useSelector(selectFulfilledForIds[type]) ||
    []) as string[];

  const [itemIdsInPendingState, setItemIdsInPendingState] = useState<string[]>(
    []
  );
  const [itemIdsInQueryState, setItemIdsInQueryState] = useState<string[]>([]);
  const [ItemIdsToCall, setItemIdsToCall] = useState<string[]>([]);

  useEffect(() => {
    const idsWithoutAnswer = items
      ?.filter((item) => !item.value)
      ?.map((item) => item.id);

    const idsWithStatusPending = itemsStatus
      ?.filter(
        (item) => item.answer_in_progress && idsWithoutAnswer.includes(item.id)
      )
      ?.map((item) => item.id);

    setItemIdsInPendingState(idsWithStatusPending);

    const idsToCall = idsWithStatusPending?.filter(
      (id) => !itemIdsInQueryState.includes(id)
    );

    setItemIdsToCall(idsToCall);
  }, [itemsStatus]);

  useEffect(() => {
    if (ItemIdsToCall?.length) {
      setItemIdsInQueryState([...itemIdsInQueryState, ...ItemIdsToCall]);
      dispatch(fetchData[type]({ botId, task_ids: ItemIdsToCall }));
    }
  }, [ItemIdsToCall, botId, dispatch, type]);

  useEffect(() => {
    if (itemValueFulfilledForIds?.length) {
      const newIdsInQueryState = itemIdsInQueryState?.filter(
        (id) => !itemValueFulfilledForIds.includes(id)
      );
      setItemIdsInQueryState(newIdsInQueryState);
    }
  }, [itemValueFulfilledForIds]);

  useEffect(() => {
    if (!botId) return;

    const intervalLogic = setInterval(() => {
      if (fetchStatus[type]) {
        dispatch(fetchStatus[type]({ botId, timestamp: Date.now() }));
      }
    }, REFETCH_INTERVAL_MS);

    return () => {
      clearInterval(intervalLogic);
    };
  }, [botId, dispatch, type]);

  return { itemIdsInPendingState, items };
};
