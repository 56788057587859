import { WS_URL } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Socket } from 'socket.io-client';
import { getAccessToken } from 'store/authentication';
import { IWSAnswerItem, updateQuestionsValues } from 'store/chat-assistant';

export interface DefaultEventsMap {
  [event: string]: (...args: any[]) => void;
}

export const CallAssistantCenter = () => {
  const accessToken = useAppSelector(getAccessToken);
  const dispatch = useAppDispatch();

  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null)

  useEffect(() => {
    if (!accessToken) return;

    const socketX = io(`${WS_URL}/call-assistant`, {
      transports: ['websocket'],
      forceNew: true,
      query: {
        accessToken,
      },
    });
    setSocket(socketX)
  }, [accessToken])


  useEffect(() => {
    if (!socket) return;

    const onConnect = () => {
      console.log('-- Connected to the server');
      setIsConnected(true);
      socket.emit('getQuestionValues');
    }

    const onQuestionUpdated = (data: IWSAnswerItem[] | null) => {
      console.log('>> onQuestionUpdated:', data);
      dispatch(updateQuestionsValues(data))
    }

    const onDisconnect = () => {
      console.log('Disconnected to the server');
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('questionUpdated', onQuestionUpdated);
    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });


    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('questionUpdated', onQuestionUpdated);
    };
  }, [socket, dispatch]);

  return (
    <></>
  );
};
