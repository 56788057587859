import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import {
  CopilotMeetingType,
  IChatAssistantState,
  IConfigureCopilotMeetingForm,
  IDynamicListItem,
  IGetAIAnswerResponse,
  IUploadInPersonRecordingData,
  IWSAnswerItem,
} from "./types";
import {
  createBot,
  downloadVideo,
  downloadTranscript,
  getPastDataAIAnswer,
  getQuestionsStatus,
  getTranscript,
  getTranscriptAIAnswer,
  getPostCallSummary,
  getTranscriptVideoUrl,
  getDataPointsStatus,
  getDataPointValue,
  getHeadsUpDisplay,
  getQuestionValue,
  getTranscriptAudioUrl,
} from "./actions";
import { toast } from "react-toastify";

const initialState: IChatAssistantState = {
  botName: "",
  meetingLink: "",
  clientId: "",
  isCreateBotProcessing: false,
  ongoingCallBotId: "",
  pastDataSummary: "",
  isGetTranscriptProcessing: false,
  isGetTranscriptError: false,
  isTranscriptTruncated: false,
  transcript: [],
  fullTranscript: [],
  transcriptLastWord: null,
  transcriptLastWordTS: 0,
  isTranscriptStale: false,
  isGetTranscriptAIAnswerProcessing: false,
  transcriptAIAnswers: [],
  isGetPastDataAIAnswerProcessing: false,
  pastDataAIAnswers: [],
  isGetDataPointValueProcessing: false,
  isGetDataPointsStatusProcessing: false,
  dataPointValueFulfilledForIds: [],
  dataPointsStatus: [],
  dataPoints: [],

  isGetQuestionsStatusProcessing: false,
  isGetQuestionValueProcessing: false,
  questions: [],
  questionValueFulfilledForIds: [],
  questionsStatus: [],

  isDownloadingVideo: false,
  downloadVideoBlobObjectUrl: "",
  isDownloadingTranscript: false,
  downloadTranscriptBlobObjectUrl: "",

  isGetTranscriptAudioUrlProcessing: false,
  audioUrl: "",
  isDownloadingAudio: false,
  downloadAudioBlobObjectUrl: "",

  isGetPostCallSummaryProcessing: false,
  postCallSummary: {
    summary: "",
    action_items: "",
    follow_up_mail: "",
  },
  isGetTranscriptVideoUrlProcessing: false,
  videoUrl: "",
  isGetHeadsUpDisplayProcessing: false,
  headsUpDisplayItems: [],

  savedCopilotConfigurationFormValues: null,
  savedCopilotConfigurationMeetingType: null,

  inPersonRecordingData: null,
};

const TRANSCRIPT_MAX_WORDS = 300;

const chatAssistantSlice = createSlice({
  name: "chatAssistant",
  initialState,
  reducers: {
    resetRedux: () => {},
    resetChatAssistant: () => {
      return { ...initialState };
    },
    resetVideoUrl: (state) => {
      state.videoUrl = "";
    },
    resetAudioUrl: (state) => {
      state.audioUrl = "";
    },
    resetProcessingIndicators: (state) => {
      state.isCreateBotProcessing = false;
      state.isGetTranscriptProcessing = false;
      state.isGetTranscriptError = false;
      state.isTranscriptTruncated = false;
      state.isTranscriptStale = false;
      state.isGetTranscriptAIAnswerProcessing = false;
      state.isGetPastDataAIAnswerProcessing = false;
      state.isGetDataPointValueProcessing = false;
      state.isGetDataPointsStatusProcessing = false;
      state.isGetQuestionsStatusProcessing = false;
      state.isGetQuestionValueProcessing = false;
      state.isDownloadingVideo = false;
      state.isDownloadingTranscript = false;
      state.isGetPostCallSummaryProcessing = false;
      state.isGetTranscriptVideoUrlProcessing = false;
      state.isGetHeadsUpDisplayProcessing = false;
    },
    updateSavedCopilotConfigurationFormValues: (
      state,
      action: PayloadAction<IConfigureCopilotMeetingForm | null>
    ) => {
      state.savedCopilotConfigurationFormValues = action.payload;
    },
    updateSavedCopilotConfigurationMeetingType: (
      state,
      action: PayloadAction<CopilotMeetingType | null>
    ) => {
      state.savedCopilotConfigurationMeetingType = action.payload;
    },
    setInPersonRecordingData: (
      state,
      action: PayloadAction<IUploadInPersonRecordingData | null>
    ) => {
      state.inPersonRecordingData = action.payload;
    },
    setIsCreateBotProcessing: (state, action: PayloadAction<boolean>) => {
      state.isCreateBotProcessing = action.payload;
    },
    updateQuestionsValues: (
      state,
      action: PayloadAction<IWSAnswerItem[] | null>
    ) => {
      const currentState = current(state);

      const updatedQuestions = currentState?.questions?.map((question) => {
        const wsItem = action.payload?.find((item) => item.id === question.id);

        if (wsItem) {
          return {
            ...question,
            value: wsItem.answer || wsItem.answer_in_progress || "",
          };
        } else {
          return question;
        }
      });

      state.questions = updatedQuestions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBot.pending, (state) => {
        state.isCreateBotProcessing = true;
      })
      .addCase(createBot.fulfilled, (state, action) => {
        const { botId, summary, data_points, questions } = action.payload;

        if (!botId) {
          toast("We could not create a bot for the meeting", {
            type: "warning",
          });
        }

        const { meeting_link, client_id, bot_name } = action.meta.arg;

        const dataPoints = data_points
          ?.filter((item) => !!item)
          .map((item): IDynamicListItem => {
            return {
              id: item.id,
              text: item.title,
              value: "",
            };
          });
        const questionsUpdated = questions
          ?.filter((item) => !!item)
          .map((item): IDynamicListItem => {
            return {
              id: item.id,
              text: item.title,
              value: "",
            };
          });

        return {
          ...initialState,
          questions: questionsUpdated,
          dataPoints,
          ongoingCallBotId: botId,
          pastDataSummary: summary,
          botName: bot_name,
          meetingLink: meeting_link,
          clientId: client_id,
          isCreateBotProcessing: false,
        };
      })
      .addCase(createBot.rejected, (state) => {
        state.isCreateBotProcessing = false;
      })
      .addCase(getTranscript.pending, (state) => {
        state.isGetTranscriptProcessing = true;
        state.isGetTranscriptError = false;
      })
      .addCase(getTranscript.fulfilled, (state, action) => {
        const currentState = current(state);

        state.isGetTranscriptProcessing = false;
        const { transcript, call_ended } = action.payload;

        if (
          call_ended &&
          action.meta.arg.botId === currentState.ongoingCallBotId
        ) {
          state.ongoingCallBotId = "";
        }

        let startIndex = 0;
        let wordCount = 0;

        for (let i = transcript.length - 1; i >= 0; i--) {
          const currentChunk = transcript[i];
          wordCount = wordCount + (currentChunk?.words.length || 0);
          if (wordCount > TRANSCRIPT_MAX_WORDS) {
            startIndex = i;
            break;
          }
        }

        state.isTranscriptTruncated = startIndex !== 0;
        state.transcript = transcript.slice(startIndex);
        state.fullTranscript = transcript;

        if (transcript?.length) {
          const lastItemsWords = transcript[transcript.length - 1]?.words;
          const lastWord = lastItemsWords[lastItemsWords.length - 1];
          const currentState = current(state);

          if (
            lastWord?.start_time !==
            currentState?.transcriptLastWord?.start_time
          ) {
            state.transcriptLastWord = lastWord;
            state.transcriptLastWordTS = Date.now();
            state.isTranscriptStale = false;
          } else {
            const diff = Date.now() - (currentState?.transcriptLastWordTS || 0);
            state.isTranscriptStale = diff > 30000;
          }
        }
      })
      .addCase(getTranscript.rejected, (state) => {
        state.isGetTranscriptProcessing = false;
        state.isGetTranscriptError = true;
      })
      .addCase(getTranscriptAIAnswer.pending, (state, action) => {
        state.isGetTranscriptAIAnswerProcessing = true;
      })
      .addCase(getTranscriptAIAnswer.fulfilled, (state, action) => {
        state.isGetTranscriptAIAnswerProcessing = false;

        const currentState = current(state);
        const newRecord: IGetAIAnswerResponse = {
          answer: action.payload.answer || "N/A",
          question: action.meta.arg.question,
          clientId: action.meta.arg.clientId,
          botId: action.meta.arg.botId,
        };

        state.transcriptAIAnswers = [
          ...(currentState.transcriptAIAnswers || []),
          newRecord,
        ];
      })
      .addCase(getTranscriptAIAnswer.rejected, (state) => {
        state.isGetTranscriptAIAnswerProcessing = false;
      })
      .addCase(getPastDataAIAnswer.pending, (state, action) => {
        state.isGetPastDataAIAnswerProcessing = true;
      })
      .addCase(getPastDataAIAnswer.fulfilled, (state, action) => {
        state.isGetPastDataAIAnswerProcessing = false;
        state.pastDataAIAnswers.push({
          answer: action.payload.answer || "N/A",
          question: action.meta.arg.question,
        });
      })
      .addCase(getPastDataAIAnswer.rejected, (state) => {
        state.isGetPastDataAIAnswerProcessing = false;
      })
      .addCase(getDataPointsStatus.pending, (state, action) => {
        state.isGetDataPointsStatusProcessing = true;
      })
      .addCase(getDataPointsStatus.fulfilled, (state, action) => {
        state.isGetDataPointsStatusProcessing = false;
        state.dataPointsStatus = action.payload || [];
      })
      .addCase(getDataPointsStatus.rejected, (state) => {
        state.isGetDataPointsStatusProcessing = false;
      })
      .addCase(getDataPointValue.pending, (state, action) => {
        state.isGetDataPointValueProcessing = true;
      })
      .addCase(getDataPointValue.fulfilled, (state, action) => {
        state.isGetDataPointValueProcessing = false;
        state.dataPoints = action.payload;
        state.dataPointValueFulfilledForIds = action.meta.arg.task_ids;
      })
      .addCase(getDataPointValue.rejected, (state) => {
        state.isGetDataPointValueProcessing = false;
      })
      .addCase(getQuestionsStatus.pending, (state, action) => {
        state.isGetQuestionsStatusProcessing = true;
      })
      .addCase(getQuestionsStatus.fulfilled, (state, action) => {
        state.isGetQuestionsStatusProcessing = false;
        state.questionsStatus = action.payload || [];
      })
      .addCase(getQuestionsStatus.rejected, (state) => {
        state.isGetQuestionsStatusProcessing = false;
      })
      .addCase(getQuestionValue.pending, (state, action) => {
        state.isGetQuestionValueProcessing = true;
      })
      .addCase(getQuestionValue.fulfilled, (state, action) => {
        state.isGetQuestionValueProcessing = false;
        state.questions = action.payload;
        state.questionValueFulfilledForIds = action.meta.arg.task_ids;
      })
      .addCase(getQuestionValue.rejected, (state) => {
        state.isGetQuestionValueProcessing = false;
      })
      .addCase(getTranscriptVideoUrl.pending, (state, action) => {
        state.isGetTranscriptVideoUrlProcessing = true;
      })
      .addCase(getTranscriptVideoUrl.fulfilled, (state, action) => {
        state.videoUrl = action.payload.video_url;
        state.isGetTranscriptVideoUrlProcessing = false;
      })
      .addCase(getTranscriptVideoUrl.rejected, (state) => {
        state.isGetTranscriptVideoUrlProcessing = false;
      })
      .addCase(getTranscriptAudioUrl.pending, (state, action) => {
        state.isGetTranscriptAudioUrlProcessing = true;
      })
      .addCase(getTranscriptAudioUrl.fulfilled, (state, action) => {
        state.audioUrl = action.payload.audio_url;
        state.isGetTranscriptAudioUrlProcessing = false;
      })
      .addCase(getTranscriptAudioUrl.rejected, (state) => {
        state.isGetTranscriptAudioUrlProcessing = false;
      })
      .addCase(downloadVideo.pending, (state, action) => {
        state.isDownloadingVideo = true;
      })
      .addCase(downloadVideo.fulfilled, (state, action) => {
        state.isDownloadingVideo = false;
      })
      .addCase(downloadVideo.rejected, (state) => {
        state.isDownloadingVideo = false;
      })
      .addCase(downloadTranscript.pending, (state, action) => {
        state.isDownloadingTranscript = true;
      })
      .addCase(downloadTranscript.fulfilled, (state, action) => {
        state.isDownloadingTranscript = false;
      })
      .addCase(downloadTranscript.rejected, (state) => {
        state.isDownloadingTranscript = false;
      })
      .addCase(getPostCallSummary.pending, (state) => {
        state.isGetPostCallSummaryProcessing = true;
      })
      .addCase(getPostCallSummary.fulfilled, (state, action) => {
        state.isGetPostCallSummaryProcessing = false;
        state.postCallSummary = action.payload;
      })
      .addCase(getPostCallSummary.rejected, (state) => {
        state.isGetPostCallSummaryProcessing = false;
      })
      .addCase(getHeadsUpDisplay.pending, (state) => {
        state.isGetHeadsUpDisplayProcessing = true;
      })
      .addCase(getHeadsUpDisplay.fulfilled, (state, action) => {
        state.isGetHeadsUpDisplayProcessing = false;
        state.headsUpDisplayItems = action.payload;
      })
      .addCase(getHeadsUpDisplay.rejected, (state) => {
        state.isGetHeadsUpDisplayProcessing = false;
      });
  },
});

export const {
  resetChatAssistant,
  resetProcessingIndicators,
  resetVideoUrl,
  updateSavedCopilotConfigurationFormValues,
  updateSavedCopilotConfigurationMeetingType,
  setInPersonRecordingData,
  resetAudioUrl,
  resetRedux,
  setIsCreateBotProcessing,
  updateQuestionsValues
} = chatAssistantSlice.actions;

export default chatAssistantSlice.reducer;
