import { GENERAL_CONVERSATION_ID, WS_URL } from 'const';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { getConversationId, selectIntercomChatState, selectIsGettingIntercomChatInfo, setIntercomChatVisibility } from 'store/chat';
import { mailboxActions, selectSelectedThread } from 'store/mailbox';
import { CONVERSATION_RECORD_TYPE, IConversationRecord, IMarkAsReadPayload, markAsRead, selectGeneralConversationRecord, updateGeneralConversationRecord } from 'store/notifications';
import { debounce } from 'lodash';
import { Socket } from 'socket.io-client';

export interface DefaultEventsMap {
    [event: string]: (...args: any[]) => void;
}

interface INotificationsCenter {
    id: string
}

const NotificationsCenter = ({ id }: INotificationsCenter) => {
    const dispatch = useAppDispatch();

    const intercomChatState = useAppSelector(selectIntercomChatState);
    const isGettingIntercomChatInfo = useAppSelector(selectIsGettingIntercomChatInfo);
    const selectedThread = useAppSelector(selectSelectedThread);
    const generalConversationRecord = useAppSelector(selectGeneralConversationRecord);

    const [isConnected, setIsConnected] = useState(false);
    const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null)

    const markAsReadDebouncedDispatch = useCallback(
        debounce((payload: IMarkAsReadPayload) => {
            dispatch(markAsRead(payload));
        }, 2000), []
    )

    useEffect(() => {
        const socketX = io(`${WS_URL}/notifications`, {
            transports: ['websocket'],
            forceNew: true, 
        });
        setSocket(socketX)
    }, [])


    useEffect(() => {
        if (!socket) return;

        const onConnect = () => {
            setIsConnected(true);

            socket.emit('getUnreadConversations', {
                userId: id
            });
        }

        const onDisconnect = () => {
            setIsConnected(false);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
        };
    }, [socket, id]);

    useEffect(() => {
        if (!socket) return;

        const onStatusChange = (conversationRecord: IConversationRecord) => {
            const isCurrentChatAffected = conversationRecord?.intercomConversationId === intercomChatState?.conversationId;
            const isCurrentChatOpen = intercomChatState?.isOpen;

            // Is unread is set if it is not current chat and it is not open
            const isUnreadStatus = isCurrentChatAffected && isCurrentChatOpen ? false : conversationRecord?.isUnread

            if (conversationRecord?.type === CONVERSATION_RECORD_TYPE.THREAD) {
                dispatch(mailboxActions.updateThreadChatIndicators({
                    thread_id: conversationRecord?.emailThreadId,
                    has_ai_chat: conversationRecord?.isActive,
                    has_ai_chat_with_unread: isUnreadStatus
                }))

                // Open if current chat is affected and is unread message
                if (isCurrentChatAffected && !isCurrentChatOpen && conversationRecord?.isUnread) {
                    dispatch(setIntercomChatVisibility(true))
                }
            } else {
                dispatch(updateGeneralConversationRecord(conversationRecord));

                // Open general if unread msg and no other chat open 
                if (!selectedThread && !isCurrentChatOpen && conversationRecord?.isUnread) {
                    dispatch(getConversationId({ threadId: GENERAL_CONVERSATION_ID, forceIsOpen: true }));
                }
            }
        }

        socket.on('conversationStatusChange', onStatusChange);

        return () => {
            socket.off('conversationStatusChange', onStatusChange);
        };
    }, [socket, intercomChatState, selectedThread, dispatch]);


    useEffect(() => {
        if (isGettingIntercomChatInfo || !intercomChatState) return;

        const payload: IMarkAsReadPayload = {
            conversation_id: intercomChatState?.conversationId || '',
        };

        if (intercomChatState?.threadId === GENERAL_CONVERSATION_ID) {
            // Mark as read GENERAL conversations
            if (
                generalConversationRecord?.isUnread &&
                intercomChatState?.isOpen &&
                intercomChatState?.conversationId
            ) {
                markAsReadDebouncedDispatch(payload);
            }
        } else {
            // Mark as read thread conversations
            if (
                selectedThread?.thread_id === intercomChatState?.threadId &&
                selectedThread?.has_ai_chat_with_unread &&
                intercomChatState?.conversationId
            ) {
                markAsReadDebouncedDispatch(payload);
            }
        }
    }, [intercomChatState, isGettingIntercomChatInfo, selectedThread, markAsReadDebouncedDispatch, generalConversationRecord])



    return (
        <div></div>
    );
};

export default NotificationsCenter;
